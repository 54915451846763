export default {
  getAchievements: (state) => {
    return state.achievements;
  },
  getAchievementById: (state) => (id) => {
    return state.achievements.find((el) => el.id == id);
  },
  getRating: (state) => {
    return state.rating;
  },
  getRating2: (state) => {
    return state.rating2;
  },
  getRating2points: (state) => {
    return state.rating2points;
  },
  getRatingFull: (state) => {
    return state.ratingFull;
  },
};
