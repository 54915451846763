// пользователь
export const APIPOINT_USER_REG = "ajax_userReg";
export const APIPOINT_USER_AUTH = "ajax_userAuth";
export const APIPOINT_USER_CHECKTOKEN = "ajax_userCheckToken";
export const APIPOINT_USER_UPDATEPASS = "ajax_userUpdatepass";
export const APIPOINT_USER_GETUSERINFO = "ajax_userGetInfo";
export const APIPOINT_USER_LOGOUT = "ajax_userLogOut";

// списки
export const APIPOINT_GET_LEVELS = "ajax_getLevels";
export const APIPOINT_GET_FORMS = "ajax_getForms";
export const APIPOINT_GET_TYPES = "ajax_getTypes";
export const APIPOINT_GET_RESULTS = "ajax_getResults";
export const APIPOINT_GET_DIRECTIONS = "ajax_getDirections";
export const APIPOINT_GET_DISCIPLES = "ajax_getDisciples";
export const APIPOINT_GET_CHILDREN = "ajax_userGetChildren";
export const APIPOINT_GET_EVENTSFULL = "ajax_getEvents";

// достижения
export const APIPOINT_ACH_SUBMIT = "ajax_achSubmit";
export const APIPOINT_ACH_LIST = "ajax_achList";
export const APIPOINT_ACH_CORRECT = "ajax_achCorrect";
export const APIPOINT_ACH_CONFIRM = "ajax_achConfirm";
export const APIPOINT_ACH_UNCONFIRM = "ajax_achUnconfirm";
export const APIPOINT_ACH_REMOVE = "ajax_achRemove";
export const APIPOINT_ACH_IMAGE = "ajax_achImage";

// списки для суфлёра
export const APIPOINT_HELPLISTS = "ajax_helpLists";

// рейтинг
export const APIPOINT_GETRATING = "ajax_getRating";
export const APIPOINT_GETRATING2 = "ajax_getRating2";
