import getters from "@/store/modules/achievements/getters";
import mutations from "@/store/modules/achievements/mutations";
import actions from "@/store/modules/achievements/actions";
export default {
  state: {
    achievements: [],
    rating: { art: 0, intel: 0, sport: 0 },
    ratingFull: [],
    rating2: [],
    rating2points: [],
  },
  getters,
  mutations,
  actions,
};
